.slider-container {
   width: 100%;

   .slideImg {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
         height: 320px;
         width: 100%;
         object-fit: contain;
      }
   }

   .description {
      font-size: 20px;
      line-height: 23px;
      text-align: center;
      letter-spacing: 0.14994px;
      margin: 30px;
   }
}
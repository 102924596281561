.socialContainer {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    z-index: 1;

    .socialItem {
        display: flex;
        margin-right: 24px;

        a {
            display: inline-block;
            svg {
                transition: fill 0.15s ease-out, transform 0.15s ease-out;
                font-size: xx-large;

                @media only screen and (max-width: 768px) {
                    font-size: large;
                }

                @media only screen and (max-width: 480px) {
                    font-size: medium;
                }
            }

            &:hover svg {
                fill: #F46C00;
                transform: scale(1.1);
            }
        }
    }
}

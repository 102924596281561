.card-radio {
  display: flex;
  flex-direction: row;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 16px;
  margin-bottom: 16px;
  font-weight: 400;
  align-items: center;

  &__icon {
    padding-right: 15px;
    display: none;
  }

  &__content {
    display: flex;
    flex-direction: column;
    flex: 1;

    .title {
      font-size: 16px;
    }

    .subtitle {
      font-size: 12px;
    }
  }

  @media (min-width: 768px) {
    height: 120px;

    &__icon {
      display: block;
    }
  }

  @media (max-width: 480px) {
    height: auto;
  }

  &.disabled {
    opacity: 0.5; 
    pointer-events: none; 
    background-color: #f2f2f2;
  }
 
}
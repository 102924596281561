.logout-container {
    position: absolute;
    bottom: 45px;
    left: 30px;
    display: flex;
    width: 53px;
    height: 19px;
    padding: 0px;
    cursor: pointer;
    z-index: 2;

    .logout-button {
        padding: 10px;
        color: rgba(255, 255, 255, 0.8);
        cursor: pointer;
        font-size: large;
    }
}
.styledDocumentImage {
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   align-items: center;
   text-align: center;
   padding: 40px;
   height: 100%;
   box-sizing: border-box;
   img {
      width: 100%;
      height: auto;
   }
   h4 {
      font-size: 18px;
      line-height: 18px;
      letter-spacing: 0.14994px;
      margin: 10px 0 0 0;
      font-weight: 400;
      color: "#666666";
   }
   @media (max-width: 1220px) {
      padding: 30px 20px 30px 20px;
      margin-top: 15px;
      height: auto;
      img {
         width: 80%;
         height: auto;
      }
      h4 {
         font-size: 16px;
         line-height: 16px;
      }
   }
   @media (max-width: 768px) {
      padding: 23px 20px 23px 20px;
      margin: 0 auto;
      margin-top: 20px;
      img {
         width: 80%;
         height: auto;
      }
      h4 {
         font-size: 16px;
         line-height: 16px;
      }
   }
   @media (max-width: 480px) {
      padding: 23px 20px 23px 20px;
      margin: 0 14px;
      margin-top: 20px;
      img {
         width: 80%;
         height: auto;
      }
      h4 {
         font-size: 16px;
         line-height: 16px;
      }
   }
}

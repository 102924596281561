@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;500&display=swap");


$font-family: "Montserrat", sans-serif;
$shadow-color: rgba(0, 0, 0, 0.11);
$border-radius: 15px;
$padding-vertical: 6px;
$padding-horizontal: 15px;
$rotate-deg: -90deg;
$right-offset: -63px;
$top-offset: 192px;
$text-color: #000;
$text-size: 13px;
$text-line-height: 18px;

.helpContainer {
   box-shadow: -2px 0px 6px $shadow-color;
   border-radius: $border-radius $border-radius 0 0;
   transform: rotate($rotate-deg);
   padding: $padding-vertical $padding-horizontal;
   position: absolute;
   right: $right-offset;
   top: $top-offset;
   display: flex;
   cursor: pointer;
   text-decoration: none;

   p {
      margin: 0;
      font-size: $text-size;
      line-height: $text-line-height;
      color: $text-color;
      margin-right: 5px;
      font-family: $font-family;
   }

      svg {
       transition: fill 0.15s ease-out, transform 0.15s ease-out;
          fill: #25D366 ;
          font-size: large
      }
      &:hover svg {
          transform: scale(1.2);
      }
   
}


   .left-content {
      position: sticky;
      top: 0;
      min-height: 100vh;
      display: block;

      @media (max-width: 1220px) {
         display: none;
      }
   }

   .right-content {
      overflow-y: auto;
      margin: 55px;
      display: flex;
      justify-content: center;
      align-items: center;

      @media only screen and (min-width: 768px) and (max-width: 1024px) {
         margin: 80px;
      }

      @media screen and (min-width: 1220px) {
         padding-left: 30px;
      }

   }
.typographyContainer {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    font-weight: 400;
    color: #373737;

    .title {
        font-size: 30px !important;

        @media only screen and (max-width: 768px) {
            font-size: 24px !important; 
        }

        @media only screen and (max-width: 480px) {
            font-size: 20px !important;
        }
    }
}

$mobile-max-width: 1220px;

.styledHeader {
    display: block;
    width: 100%;
    padding: 25px 20px;
    margin: 0;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    transition: all 0.2s ease-out;
    box-sizing: border-box;
 
    @media (max-width: #{$mobile-max-width}) {
       position: absolute;
       top: 0;
       left: 0;
       padding: 15px 20px;
       padding-left: 14px;
    }
 }

.logoHeader {
   margin: 0;
   img {
      width: 200px;
   }
   @media (max-width: #{$mobile-max-width}) {
      display: none;
      max-width: 85px;
      img {
         width: 300px;
      }
   }
   
}
